// Import all 3rd party plugins styles
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  // xl: 1170px,
  xl: 1200px,
) !default;
@import "node_modules/bootstrap/scss/bootstrap";

.dropdown-toggle {
  &::after {
    content: "\e803";

    font-family: "fontello";
    font-style: normal;
    font-weight: 400;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    margin: 0;
    vertical-align: unset;
    border: unset;
  }
}

.form-control {
  position: relative;
  &:required {
    & ~ .form-control__required {
      display: block;
    }
  }

  &-wrap {
    position: relative;
  }
  &__required {
    display: none;
    position: absolute;
    color: red;
    font-size: 15px;
    right: 10px;
    top: 0;
  }
}

.form-group {
  label {
    font-weight: normal;
    font-size: 15px;
    line-height: 17px;
    margin-bottom: 12px;
  }
  input {
    border-radius: 3px;
    background: #ffffff;
    border: 1px solid #c5c7cd;
    &:focus {
      box-shadow: 0 0 0px 2px #00a1e6;
    }
  }
  textarea {
    border-radius: 3px;
    background: #ffffff;
    border: 1px solid #c5c7cd;
    &:focus {
      box-shadow: 0 0 0px 2px #00a1e6;
    }
  }

  &.has-error {
    input,
    textarea {
      box-shadow: 0 0 0px 2px #ff0000 !important;
      border-color: transparent;
    }

    .form-group__error {
      display: block;
    }
  }
  &__error {
    display: none;

    font-size: 15px;
    line-height: 17px;
    text-align: right;
    color: #ff0000;

    margin-top: 5px;
  }
}

.pagination {
  .page-item {
    border-radius: 3px;

    &:not(:last-child) {
      margin-right: 20px;
    }
    &:last-child {
      margin-left: 0;
    }

    .page-link {
      padding: 10px 18px;
      border-radius: 3px;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #767676;

      border: 1px solid #d1d4db;
    }

    &.active {
      &:hover .page-link,
      .page-link {
        background: #00a1e6;
        border: 1px solid #00a1e6;
        color: #fff;
      }
    }
    &:hover {
      .page-link {
        background: #fff;
        border: 1px solid #00a1e6;
        color: #00a1e6;
      }
    }
  }
  .page-item-nav-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    margin-left: 20px;
    &:hover {
      .page-link {
        border: none;
      }
    }
    .page-link {
      width: 35px;
      padding: 0;
      border: 0;
      color: #767676;
      &:hover {
        color: #00ac4f;
        background: transparent;
      }
    }
  }
  .page-item-spacer {
    display: block;
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: -10px;

    font-size: 15px;
    line-height: 24px;
    color: #767676;
  }
}

@media (max-width: 992px) {
  .pagination {
    .page-item {
      display: none;

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-last-child(2),
      &:nth-last-child(1) {
        display: flex;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }

      .page-link {
        padding: 5px 8px;
      }
    }
    .page-item-nav-item {
      margin-right: 30px !important;
      margin-left: 10px !important;
      .page-link {
      }
    }
  }
}

.breadcrumb {
  padding: 0;
  background: none;
  margin-top: 34px;

  .breadcrumb-item {
    color: #fff;
    font-size: 15px;
    line-height: 24px;

    &::before {
      color: #fff;
    }

    a {
      color: #fff !important;
    }
  }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.25rem;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    padding-right: 0.25rem;
  }
}

.custom-checkbox-container {
  display: block;
  position: relative;
  padding-left: 34px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-checkbox-container input.custom-checkbox__checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.custom-checkbox__checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  width: 24px;
  height: 24px;
  border-radius: 3px;
  border: 1px solid #767676;
}

.custom-checkbox__text {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  color: #767676;
}

.custom-checkbox-container:hover
  input.custom-checkbox__checkbox
  ~ .custom-checkbox__checkmark {
  background-color: #fff;
}

.custom-checkbox-container
  input.custom-checkbox__checkbox:checked
  ~ .custom-checkbox__checkmark {
  background-color: #fff;
}

.custom-checkbox__checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox-container
  input.custom-checkbox__checkbox:checked
  ~ .custom-checkbox__checkmark:after {
  display: block;
}

.custom-checkbox-container .custom-checkbox__checkmark:after {
  top: -4px;
  left: -4px;

  display: none;

  content: "\e808";
  font-size: 31px;
  color: #767676;

  font-family: "fontello";
  font-style: normal;
  font-weight: 400;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.custom-number-input {
  padding-right: 45px !important;

  &__btn {
    position: absolute;
    top: 0;
    right: 16px;
    border: none;
    background: none;
    outline: none !important;

    &-up {
      top: -8px;
      i {
        color: #767676;
        font-size: 22px;
        &::before {
          transform: rotate(180deg);
        }
      }
    }
    &-down {
      top: 8px;
      i {
        color: #767676;
        font-size: 22px;
      }
    }
  }
}

/*the container must be positioned relative:*/
.custom-select {
  &-wrap {
    position: relative;

    .select-selected {
      background-color: #fff;
      border: 1px solid #c5c7cd;
      border-radius: 3px;
      color: #767676;

      &:after {
        position: absolute;
        top: 18px;
        right: 10px;

        font-size: 24px;
        color: #767676;

        content: "\e803";
        font-family: "fontello";
        font-style: normal;
        font-weight: 400;
        display: inline-block;
        text-decoration: inherit;
        width: 1em;
        text-align: center;
        font-variant: normal;
        text-transform: none;
        line-height: 1em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
      &.select-arrow-active {
        background: #00a1e6;
        border: 2px solid #00a1e6;
        border-bottom: 0;
        color: #fff;
      }
      &.select-arrow-active:after {
        transform: rotate(180deg);
        color: #fff;
      }
    }

    /*style the items (options), including the selected item:*/
    .select-items div,
    .select-selected {
      padding: 8px 16px;
      cursor: pointer;
      user-select: none;
    }

    /*style items (options):*/
    .select-items {
      position: absolute;
      background-color: #fff;
      top: calc(100% - 2px);
      left: 0;
      right: 0;
      z-index: 99;
      border: 2px solid #00a1e6;
      border-top: 0;
    }

    /*hide the items when the select box is closed:*/
    .select-hide {
      display: none;
    }
    .select-items div:hover,
    .same-as-selected {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  // display: none; /*hide original SELECT element:*/
  opacity: 0;
  position: absolute;
  z-index: -1;
  pointer-events: none;
  user-select: none;
}
